
  import DialogModule from 'models/dialog_module'
  import { alert, confirm } from 'helpers'

  import { ActionMenu, Badge, Button, EnvironmentBadge, Input, TableCell, TableRow } from 'components/generic'
  import { PencilIcon, RefreshIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      module: DialogModule

    emits: ['remove']

    data: ->
      actionMenuItems = [
        {icon: PencilIcon, label: 'Edit conversation flow', method: @goto}
      ]
      if @module.key? && !@module.isCoreBehavior
        actionMenuItems.push(icon: PencilIcon, label: 'Edit name & description', method: @edit)
        if @module.status == 'ACTIVE'
          actionMenuItems.push(icon: PencilIcon, label: 'Deactivate', method: @deactivate)
        else
          actionMenuItems.push(icon: PencilIcon, label: 'Activate', method: @activate)
      if @module.key? && @module.isCoreBehavior
        actionMenuItems.push(icon: RefreshIcon, label: 'Reset', method: @remove)
      else if @module.key?
        actionMenuItems.push(icon: XIcon, label: 'Delete', method: @remove)

      copy: @module.clone()
      editing: false
      actionMenuItems: actionMenuItems

    computed:
      isCoreBehaviour: ->
        @$route.name == 'coreBehaviourList'
      routerTarget: ->
        return null if !@module.key
        name: if @isCoreBehaviour then 'coreBehaviour' else 'module'
        params: {moduleKey: @module.key}
      descriptionInProgress: ->
        @module.description.startsWith('<auto-generation::inProgress>')
      descriptionFail: ->
        @module.description.startsWith('<auto-generation::fail>')
      formattedDescription: ->
        @module.description.replace(/<auto-generation::(inProgress|fail)>/, '')
      statusBadgeColor: ->
        switch @module.status
          when 'DRAFT' then 'blue'
          when 'ACTIVE' then 'green'
          when 'INACTIVE' then 'gray'

    methods:
      goto: ->
        @$router.push(@routerTarget)
      edit: ->
        @editing = true
        @$nextTick => @$refs.labelInput.focus()
      cancel: ->
        if @module.key?
          @copy = @module.clone()
          @editing = false
        else
          @$emit('remove')
      save: ->
        @copy.saveSettings()
          .then (data) =>
            @module.update(data)
            @editing = false
            @copy = @module.clone()
          .catch (response) =>
            # alert is shown, row stays in editing mode
      activate: ->
        @copy.status = 'ACTIVE'
        @save()
      deactivate: ->
        @copy.status = 'INACTIVE'
        @save()
      remove: ->
        verb = if @module.isCoreBehavior then 'reset' else 'delete'
        return unless await confirm("Do you really want to #{verb} module “#{@module.label}”?")
        @module.botConfig.deleteDialogModule(@module)

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      Button: Button
      EnvironmentBadge: EnvironmentBadge
      Input: Input
      PencilIcon: PencilIcon
      TableCell: TableCell
      TableRow: TableRow
      XIcon: XIcon
