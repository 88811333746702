
  import DialogModule from 'models/dialog_module.coffee'
  import DialogNode from 'models/dialog_node.coffee'
  import DialogTarget from 'models/dialog_target.coffee'
  import { Combobox } from 'components/generic'

  export default
    props:
      modelValue: DialogTarget
      targetNodeTypes: Array
      dialogModule: DialogModule # if this is provided, only the given module will be searched
      dialogNode: DialogNode
      includeModule: DialogModule
      statuses:
        type: Array
        default: ['ACTIVE']
      displayLabel:
        type: Boolean
        default: true
      disabled:
        type: Boolean
        default: false
      useActiveBot:
        type: Boolean
        default: false

    data: ->
      target: @modelValue
      targetNode: null
      collection: []
      comboboxLabel: if @displayLabel then 'Target node' else null

    computed:
      availableTargetNodes: ->
        prependedList = [key: null, label: '<none>']
        if @target.nodeKey? && !@target.node?
          prependedList.push(@targetNode) if @targetNode?
        prependedList.concat(@collection)
      botToUse: ->
        if @useActiveBot then @$root.activeBot else @$root.currentBot

    watch:
      modelValue: ->
        @target = @modelValue
        @setCopies()

    created: ->
      @setCollection().then =>
        @setCopies()

    methods:
      setCollection: ->
        @collection = if @$root.currentModule?
          if @targetNodeTypes.includes('BotIntent') && @targetNodeTypes.includes('DialogAction')
            @$root.currentModule.actionsAndBotIntents.slice().reverse()
          else if @targetNodeTypes.includes('UserIntent')
            @$root.currentModule.userIntents.slice().reverse()
          else
            []
        else
          await @searchIntents()
      setCopies: ->
        if @target.ofOtherModule
          @botToUse.config.getNode(@target.moduleKey, @target.nodeKey)
            .then (node) => @targetNode = node
        else if @target.node
          @targetNode = @target.node
        else if @target.nodeKey?
          @targetNode = {key: @target.nodeKey, label: @target.nodeKey, invalid: true}
        else
          @targetNode = @availableTargetNodes[0]
      update: ->
        @$emit('update:modelValue', @target.set(@targetNode))
      intentLabel: (intent) ->
        intent?.label
      inputLabel: (intent) ->
        return @intentLabel(intent) if intent?.key?
        ''
      invalidIntent: (intent) ->
        intent?.invalid
      searchIntents: (query) ->
        if @dialogModule?
          @dialogModule.searchNodes(query, types: @targetNodeTypes, node: @dialogNode)
        else
          @botToUse.config.searchNodes(
            query
            types: @targetNodeTypes
            node: @dialogNode
            statuses: @statuses
            includeModuleKey: @includeModule?.key
          )
      open: ->
        @$refs.combobox.open()

    components:
      Combobox: Combobox
