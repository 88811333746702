import BotbuilderApi from 'models/botbuilder_api.coffee'
import BotConfig from 'models/bot_config.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'

export default class Bot
  @active: ({bots, light} = {light: true}) ->
    if !bots?
      bots = await BotbuilderApi.getBots()
    activeBot = null
    PlatformApi2.getActiveBotId(noAlert: true)
      .then (activeBotId) => activeBot = bots.find((bot) -> bot.id == activeBotId) || bots[0]
      .catch => activeBot = bots[0]
      .then => activeBot.loadConfig(light: light)
      .then => activeBot

  constructor: (data = {}) ->
    @config = new BotConfig(@)
    @update(data)

  update: (data = {}) ->
    @id = data.id if data.id?
    @label = data.label if data.label?
    @description = data.description if data.description?

  save: ->
    apiMethod = if @id? then 'updateBot' else 'createBot'
    BotbuilderApi[apiMethod](@)
      .then (data) => @update(data)

  delete: ->
    BotbuilderApi.deleteBot(@)

  createFromTemplate: (template) ->
    BotbuilderApi.createBotFromTemplate(@, template)
      .then (data) => @update(data)

  loadConfig: ({light} = {light: true}) ->
    @_configLoading = true
    BotbuilderApi.getConfig(@id, light)
      .then (data) => @_config.update(data)
      .then => BotbuilderApi.getConfigMetaInfo(@id)
      .then (data) => @_config.setMetaInfo(data)
      .finally => @_configLoading = false

  getHints: (params)->
    BotbuilderApi.getHints(@, params)

  Object.defineProperties @prototype,
    config:
      get: ->
        @loadConfig() if @_config.empty && !@_configLoading
        @_config
      set: (@_config) -> @_config
    export:
      get: ->
        id: @id
        label: @label
        description: @description
