import BotbuilderApi from 'models/botbuilder_api'
import ContextParameter from 'models/context_parameter'
import EdgeVariable from 'models/edge_variable'
import InternalParameter from 'models/internal_parameter'
import Project from 'models/project'
import SlotRole from 'models/slot_role'

export default class DialogNode
  @TYPES = [
    'BotIntent'
    'DialogAction'
    'TriggerIntent'
    'UserIntent'
  ]

  @typed: (dialogModule, data) ->
    nodeClass = switch data.type
      when 'TriggerIntent'
        require('./trigger_intent.coffee')
      when 'UserIntent'
        require('./user_intent.coffee')
      when 'BotIntent'
        require('./bot_intent.coffee')
      when 'DialogAction'
        require('./dialog_action.coffee')
    new nodeClass.default(dialogModule, data)

  save: ->
    if @key
      BotbuilderApi.updateNode(@).then (result) =>
        @partialUpdate(result.node)
        @setMetaInfo(result.metaInfo)
    else
      BotbuilderApi.createNode(@).then (nodeData) => @update(nodeData)

  delete: ->
    BotbuilderApi.deleteNode(@)
      .then (response) => @dialogModule.updateNodes(response.affectedResources)

  setMetaInfo: (value) ->
    return unless @dialogModule?
    @dialogModule.nodeMeta[@key] = value
    @dialogModule.nodeMeta[@key].externalTargetNodes = (value.externalTargetNodes || []).map (nodeInfo) =>
      DialogNode.typed(@dialogModule.botConfig.module(nodeInfo.moduleKey), nodeInfo.node)
    @dialogModule.nodeMeta[@key].externalIncomingNodes = (value.externalIncomingNodes || []).map (nodeInfo) =>
      DialogNode.typed(@dialogModule.botConfig.module(nodeInfo.moduleKey), nodeInfo.node)

  saveMetaInfo: ->
    return unless @dialogModule?.nodeMeta[@key]?
    BotbuilderApi.updateNodeMetaInfo(@, pinned: @pinned)

  searchVariables: (query, {types, limit} = {}) =>
    BotbuilderApi.search(@botId, query, node: @, types: types, limit: limit)
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) =>
            switch matchData.resourceType
              when 'ContextParameter'
                new ContextParameter(matchData.entity)
              when 'EdgeVariable'
                new EdgeVariable(matchData.entity)
              when 'InternalParameter'
                new InternalParameter(matchData.entity)
              when 'SlotRole'
                new SlotRole(matchData.entity)

  loadVariables: ->
    @_variablesLoading = true
    @searchVariables('', types: ['EdgeVariable', 'SlotRole', 'InternalParameter'], limit: 999)
      .then (@_variables) => @_variables
      .finally => @_variablesLoading = false

  getVariables: ->
    if !@_variables? && !@_variablesLoading
      @_loadingVariablesPromise = @loadVariables()
    else if @_variablesLoading
      @_loadingVariablesPromise
    else
      Promise.resolve(@_variables)

  Object.defineProperties @prototype,
    intentIdentifier:
      get: ->
        "#{@moduleKey}/#{@key}"
    moduleKey:
      get: -> @dialogModule.key
    moduleLabel:
      get: -> @dialogModule?.label
    botId:
      get: -> @dialogModule.botId
    metaInfo:
      get: ->
        @dialogModule?.nodeMeta[@key]
    environments:
      get: ->
        (Project.ENVIRONMENTS
          .map (env) -> env.key
          .filter (env) => @metaInfo?.environments?.includes(env)
        ) || ['DEV']
    # only relevant for user intents, mounted here for saveMetaInfo method
    pinned:
      get: ->
        @dialogModule?.nodeMeta[@key]?.pinned
      set: (value) ->
        return unless @dialogModule?
        @dialogModule.nodeMeta[@key] ||= {}
        @dialogModule.nodeMeta[@key].pinned = value
    externalIncomingNodes:
      get: ->
        @dialogModule?.nodeMeta[@key]?.externalIncomingNodes || []
