import { globalWidgetChannelKey } from 'rails_data'

export default ->
  window.addEventListener 'DOMContentLoaded', ->
    initWidget = ->
      style = document.createElement('style')
      style.textContent = '''
        #global-widget .toggle-button { display: none }
        #global-widget #chat-widget { top: 100px }
      '''
      document.head.appendChild(style)
      el = document.createElement('div')
      el.setAttribute('id', 'global-widget')
      el.setAttribute('data-turbolinks-permanent', '')
      document.body.appendChild(el)
      Mercury.initChatWidget(
        el,
        {apiKey: globalWidgetChannelKey, pollingInterval: 5},
        {
          userId: Globals.currentUser.botUserId
          firstName: Globals.currentUser.firstName
          lastName: Globals.currentUser.lastName
          context:
            'user.firstName': Globals.currentUser.firstName
            'user.lastName': Globals.currentUser.lastName
            'user.email': Globals.currentUser.email
            'user.company': Globals.currentUser.organizationName
        }
      )

    if window.Mercury?
      initWidget()
    else
      document.getElementById('widget-js')?.onload = initWidget

    # preserving widget’s scroll position across turbolinks visits
    cacheScrollPosition = ->
      el = document.getElementById('global-widget')
      el.setAttribute('scroll-top', el.getElementsByClassName('message-area')[0]?.scrollTop)
    restoreScrollPosition = ->
      el = document.getElementById('global-widget')
      el?.getElementsByClassName('message-area')[0]?.scrollTop = el.getAttribute('scroll-top')
    window.addEventListener 'turbolinks:before-visit', cacheScrollPosition
    # window.addEventListener 'turbolinks:render', restoreScrollPosition

    # preserve widget across turbolinks visits
    window.addEventListener 'turbolinks:visit', ->
      el = document.getElementById('global-widget')
      brot = ->
        window.removeEventListener('turbolinks:load', brot)
        document.getElementById('global-widget')?.remove()
        document.body.appendChild(el)
        restoreScrollPosition()
      window.addEventListener('turbolinks:load', brot)
