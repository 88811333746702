import { createApp } from 'vue_shims'

import Agent from 'models/agent'
import AgentTeam from 'models/agent_team'
import InboxApi from 'models/inbox_api'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import DesktopNotifier from 'models/desktop_notifier'
import PlatformApi2 from 'models/platform_api2.coffee'

import { Button, Modal } from 'components/generic'
import assignedToMeIcon from 'images/assignment_me.png'
import assignedToTeamIcon from 'images/assignment_team.png'
import assignedToNoneIcon from 'images/assignment_none.png'

initInbox2App = (element) ->
  createApp(
    data: ->
      isBotcore2 = $(element).data('is-botcore-2')

      isBotcore2: isBotcore2
      agents: $(element).data('agents').map (data) -> new Agent(data)
      agentTeams: $(element).data('agent-teams').map (data) -> new AgentTeam(data)
      agent: $(element).data('agent')
      assignedToIcons:
        me: assignedToMeIcon
        team: assignedToTeamIcon
        none: assignedToNoneIcon
      channels: $(element).data('channels')
      channelTypeLogos: Globals.channelTypeLogos
      environment: $(element).data('environment').toUpperCase()
      games: {}
      initialConversation: $(element).data('initial-conversation')
      isSuper: $(element).data('super') # determines whether message info is available to user
      languages: $(element).data('languages')
      prototyping: {}
      sending: false
      session: $(element).data('session')
      stageIds: $(element).data('stage-ids')
      stageBots: $(element).data('stage-bots')
      activeTab: null
      api: new InboxApi(
        $(element).data('session'),
        Globals.project,
        Globals.stageLevel,
        (reconnecting) => @reconnecting = reconnecting
      )
      reconnecting: false
      filters:
        stageIds: $(element).data('stage-ids')
        channel: {name: t('general.all'), uuid: ''}
        timestamp: null
        assignedTo: null
        textSearch: null
        activeHandover: true
        missingAnnotations: false
        fixParameters: []
        contextParameters: []
      earliestAnnotation: moment().subtract(3, 'months') # max age for messages to be annotated
      navigationTabs: [
        key: 'handover'
        labelKey: 'inbox.tabs.inbox'
        count: 'handover'
        labelClass: 'label label-default'
      ,
        key: 'inbox'
        labelKey: 'inbox.tabs.conversations'
        # count: 'unread'
        labelClass: 'label label-info'
      ,
        key: 'annotation'
        labelKey: 'inbox.tabs.needAnnotation'
        count: 'toAnnotate'
        labelClass: 'label label-danger'
      ]
      conversationCounts: {}
      contextParameters: if isBotcore2 then [] else {}
      headerCollapsed: false
      ignoreScroll: false
      cobrowsingSessionAvailable: $(element).data('cobrowsing-session-available')
      mayEditInbox: Globals.currentUser.may('edit_inbox', Globals.project, stageLevel: Globals.stageLevel)
      mayAnnotate: Globals.currentUser.may('annotate', Globals.project, stageLevel: Globals.stageLevel)
      mayViewForeignConversations: Globals.currentUser.may('view_foreign_conversations', Globals.project, stageLevel: Globals.stageLevel)
      inbox3Url: Routes.inbox3_project_path(Globals.projectId, Globals.stageLevel)

    computed:
      chosenChannelHtml: ->
        "<span class='ellipsis'>#{@filters.channel.name}</span>"
      assignedToLabel: ->
        return t('general.mine') if JSON.stringify(@filters.assignedTo) == JSON.stringify([@agent.id])
        return t('general.myTeams') if JSON.stringify(@filters.assignedTo) == JSON.stringify(@agent.teamIds)
        if team = @agentTeams.find (team) => JSON.stringify(@filters.assignedTo) == JSON.stringify([team.id])
          return team.name
        t('general.all')

    watch:
      headerCollapsed: ->
        if @headerCollapsed
          @ignoreScroll = true
          transitionDuration = parseFloat(getComputedStyle(@$refs.header).transitionDuration) * 1000
          setTimeout =>
            @ignoreScroll = false
          , transitionDuration

    created: ->
      window.breadcrumbs.enableEnvironmentSelector()
      if !@mayEditInbox
        @navigationTabs = @navigationTabs.filter((tab) -> tab.key != 'handover')
      if !@mayAnnotate || @isBotcore2
        @navigationTabs = @navigationTabs.filter((tab) -> tab.key != 'annotation')
      if !@mayViewForeignConversations
        @navigationTabs = @navigationTabs.filter((tab) -> tab.key != 'annotation' && tab.key != 'inbox')
      @activeTab = @navigationTabs[0].key

      @$root.api.handlers.session_stats = @setConversationCounts
      @loadChannels() if @isBotcore2

    mounted: ->
      if DesktopNotifier.needToReqestPermission()
        @$refs.notificationPermissionModal.open()
      @initInbox()

    beforeUnmount: ->
      @haltInbox()

    methods:
      requestNotificationPermission: ->
        DesktopNotifier.requestPermission()
        @$refs.notificationPermissionModal.close()
      initInbox: ->
        # load conversations
        if !@mayViewForeignConversations
          @filters.assignedTo = [@agent.id]
        if @initialConversation
          @activeTab = 'inbox'
        if @activeTab == 'inbox'
          @filters.activeHandover = false
          @filters.missingAnnotations = false
        @$refs.conversationList.loadConversations(conversation: @initialConversation)
        @loadContextParameters(@filters.stageIds...)
        @conversationCounts = {}
        window.addEventListener('beforeunload', @haltInbox)
      haltInbox: ->
        @$root.api.halt()
      loadChannels: ->
        return unless @isBotcore2
        PlatformApi2.getChannels().then (channels) =>
          @channels = Object.fromEntries(
            channels.sortByString('label')
              .filter (ch) => ch.environment == @environment
              .map (ch) -> [ch.id, {name: ch.label, type: ch.type, uuid: ch.id}]
          )
      # read/set user settings wrt. selected stage/channel
      setChannel: (channel) ->
        @track('Inbox channel selected', channel: channel)
        @filters.channel = channel
        @setFilters()
      setFilters: (context) ->
        if context == 'textSearch'
          @track("Inbox text search used", filter: @filters.textSearch)
        Vue.nextTick =>
          @$refs.conversationList.loadConversations()
      setAssignedTo: (value, label) ->
        @track('Inbox assignedTo selected', target: {ids: value, label: label})
        @filters.assignedTo = value
        @$refs.conversationList.loadConversations()
      navigateTo: (tab) ->
        # for tracking
        selectedTab = @navigationTabs.find (navTab) -> navTab.key == tab
        @track("Inbox #{t(selectedTab.labelKey, 'en')} tab viewed")
        # continue
        @activeTab = tab
        switch tab
          when 'inbox'
            @filters.activeHandover = false
            @filters.assignedTo = null
            @filters.missingAnnotations = false
            @setFilters()
          when 'handover'
            @filters.activeHandover = true
            @filters.missingAnnotations = false
            @setFilters()
          when 'annotation'
            @filters.activeHandover = false
            @filters.missingAnnotations = true
            @setFilters()
      loadContextParameters: (stageIds...) ->
        if @isBotcore2
          BotbuilderApi.getContextParameters()
            .then (cps) => @contextParameters = cps
        else
          stageIds.forEach (stageId) =>
            return if @contextParameters[stageId]?
            @api.loadContextParameters(stageId)
              .then (data) => @contextParameters[stageId] = data
      setConversationCounts: (data) ->
        Vue.set(@conversationCounts, 'unread', data.conversationsUnread)
        Vue.set(@conversationCounts, 'handover', data.conversationsHandover)
        Vue.set(@conversationCounts, 'toAnnotate', data.conversationsToAnnotate)
      collapseHeaderConditionally: ->
        return if @$refs.conversationList.contentHeight() <= $(element).height() #+ 200
        @collapseHeader()
      collapseHeader: ->
        @headerCollapsed = true
      expandHeader: ->
        return if @ignoreScroll
        @headerCollapsed = false
      track: (eventName, payload = {}) ->
        # analytics.track(eventName, payload)

    components:
      Button: Button
      TwModal: Modal

    template: '
      <div class="inbox2">

        <div v-if="isBotcore2" class="bg-MercuryRed-600 text-white font-medium flex items-center p-2">
          {{ t(\'inbox.phaseOutNotice\') }}
          <Button type="primary" size="sm" :href="inbox3Url" class="ml-3">
            {{ t(\'inbox.previewNewInbox\') }}
          </Button>
        </div>

        <div v-if="reconnecting" style="text-align: center; margin-top: 10px">Inbox is trying to reconnect...</div>

        <div class="inbox-header" ref="header" :class="{collapsed: headerCollapsed}">
          <div class="inbox-navigation">
            <a
              v-for="tab in navigationTabs"
              :key="tab.key"
              @click="navigateTo(tab.key)"
              :class="[{active: activeTab == tab.key, \'tw-hidden md:block\': tab.key == \'annotation\'}, \'inbox-navigation-tab\']"
              >
              <span>{{ t(tab.labelKey) }}</span>
              <span v-if="tab.count" :class="tab.labelClass">
                {{ conversationCounts[tab.count] == null ? \'&ensp;\' : conversationCounts[tab.count] }}
              </span>
            </a>

            <a
              v-if="mayViewForeignConversations"
              :href="api.agentStatusUrl"
              target="_blank"
              class="tw-hidden md:block inbox-navigation-tab"
              @click="track(\'Inbox live status tab viewed\')"
              >
              <i class="fas fa-user-clock"></i>
              &nbsp;
              Live Status
            </a>

            <a
              v-if="mayViewForeignConversations"
              :href="api.analyticsUrl"
              target="_blank"
              class="tw-hidden md:block inbox-navigation-tab"
              @click="track(\'Inbox analytics tab viewed\')"
              >
              <i class="far fa-chart-bar"></i>
              &nbsp;
              Analytics
            </a>
          </div>

          <div class="inbox-filters">
            <parameter-filtering :filters="filters" @filters-changed="setFilters" class="flex-container center-items"/>
            <div class="flex-container center-items">
              {{ t(\'general.channel\') }}&ensp;
              <dropdown :chosen="chosenChannelHtml">
                <li>
                  <a @click="setChannel({uuid: null, name: \'all\'})">{{ t(\'general.all\') }}</a>
                </li>
                <template v-for="channel, uuid in channels" :key="uuid">
                  <li v-if="uuid.length">
                    <a @click="setChannel(channel)">
                      <img :src="channelTypeLogos[channel.type || \'\']">
                      {{ channel.name }}
                    </a>
                  </li>
                </template>
              </dropdown>
            </div>
            <div v-if="mayViewForeignConversations && activeTab == \'handover\'" class="flex-container center-items">
              {{ t(\'general.show\') }}&ensp;
              <dropdown :chosen="assignedToLabel">
                <li>
                  <a @click="setAssignedTo([agent.id], \'mine\')">
                    <img :src="assignedToIcons.me">
                    {{ t(\'general.mine\') }}
                  </a>
                </li>
                <li>
                  <a @click="setAssignedTo(agent.teamIds, \'myTeams\')">
                    <img :src="assignedToIcons.team">
                    {{ t(\'general.myTeams\') }}
                  </a>
                </li>
                <li v-for="team in agentTeams" :key="team.id">
                  <a @click="setAssignedTo([team.id], team.name)">
                    <img :src="assignedToIcons.team">
                    {{ team.name }}
                  </a>
                </li>
                <li>
                  <a @click="setAssignedTo(null, \'all\')">
                    <img :src="assignedToIcons.none">
                    {{ t(\'general.all\') }}
                  </a>
                </li>
              </dropdown>
            </div>

            <div class="filter-input auto-margin-left">
              <input
                type="text"
                v-model="filters.textSearch"
                @keyup.enter="setFilters(\'textSearch\')"
                :placeholder="t(\'inbox.filter.searchName\')"
                >
              <div @click="setFilters(\'textSearch\')" class="icon-magnifier"></div>
            </div>
          </div>
        </div>

        <inbox2-conversation-list
          ref="conversationList"
          :filters="filters"
          @scroll-up="expandHeader"
          @scroll-down="collapseHeaderConditionally"
          @expand-conversation="collapseHeader"
          />
      </div>

      <TwModal ref="notificationPermissionModal" :title="t(\'general.desktopNotificationPreferences\')">
        <template #buttons="{ close }">
          <Button type="primary" class="ml-3" @click="requestNotificationPermission"> Ok </Button>
          <Button @click="close"> Cancel </Button>
        </template>
      </TwModal>
    '
  ).mount(element)

# initialize Vue app
$(window).on 'turbolinks:load', ->
  element = document.getElementById('inbox2-app')
  initInbox2App(element) if element?
